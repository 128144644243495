.viewer-container {
    display: flex;
    flex-direction: column;
    
    align-items: center;
    height: 100vh; /* Full viewport height */
    width: 100vw;  /* Full viewport width */
    font-family: 'Minecraftia', sans-serif; /* Using Minecraftia font for all text */
}

/* Container for the AvatarViewer component */
.avatar-viewer {
    padding-top: 3%;
    margin-bottom: 20px;
    justify-content: center;
}

/* Container for entity details */
.entity-details {
    text-align: center;
    background-color: #f4f4f4; /* Light background for the details area */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Soft shadow for a subtle depth */
    width: 80%; /* Optimal width for readability */
    max-width: 600px; /* Maximum width to maintain layout integrity */
}

/* Styling for headings inside entity details for emphasis */
.entity-details h2 {
    color: #333;
    margin-bottom: 15px;
    font-size: 20px;
}

/* Styling for paragraph elements in the entity details */
.entity-details p {
    color: #666;
    font-size: 16px;
    line-height: 1.5; /* Line height for better readability */
    margin-bottom: 10px;
}

.viewer-interactions {
    display: flex;
    justify-content: center;
    gap: 10px; /* Adjusts space between buttons */
    margin-top: 20px; /* Space above the button group */
}

.viewer-interactions button {
    padding: 10px 20px; /* Button padding */
    font-size: 16px; /* Text size inside buttons */
    cursor: pointer; /* Changes cursor to pointer on hover */
    outline: none; /* Removes outline */
    border: none; /* Removes border */
    background-color: grey; /* Button background color */
    color: white; /* Text color */
    border-radius: 5px; /* Rounded corners for the button */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.viewer-interactions button:hover {
    background-color: darkgrey; /* Changes background on hover */
}

/* Media query for smaller screens, such as mobile devices */
@media (max-width: 768px) {
    .viewer-container {
        flex-direction: column;
        align-items: center;
    }

    .avatar-viewer,
    .entity-details {
        width: 90%; /* Full width on smaller screens */
    }

    .skin-interactions button {
        width: 100%; /* Full width buttons on small screens */
        padding: 12px 20px; /* Larger touch targets for mobile */
        margin-bottom: 10px; /* Space between buttons */
        margin-right: 0; /* Remove right margin */
    }

    /* Smaller font sizes for mobile */
    .entity-details h2,
    .entity-details p,
    .skin-interactions button {
        font-size: 0.7rem;
    }
}