.skin-editor {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 50px; 
  }
  
  .editor-container {
    display: flex;
    align-items: center;
    padding-left: 20px; 
  }
  
  .canvas-container {
    margin-right: 20px; 
  }
  
  .avatar-container {
    padding-right: 20px; 
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .browser-warning {
    font-family: 'Minecraftia', sans-serif;
    color: gray;
    align-items: center;
    text-align: center;
    font-size: 0.75rem;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 100px;
    padding-right: 100px;
  }
  