.skins-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  padding-top: 1px;
}

.skin-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  overflow: hidden;
  position: relative;  /* Ensure position is set for z-index to take effect */
}

.skin-image {
  position: relative;
}

.skin-details {
  padding: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
}

.skin-details strong {
  font-family: 'Minecraftia', sans-serif;
  font-size: 0.65rem; /* Adjust the font size to your preference */
  color: black; /* Change the color to grey */
  margin-bottom: 0.25px;
}

.like-dislike-interactions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3px;
}

.like-dislike-interactions button {
  background-color: #f0f0f0;
  color: black;
  border: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'Minecraftia', sans-serif;
  transition: background-color 0.3s ease;
}

.like-dislike-interactions button:hover {
  background-color: #e0e0e0;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 25px; 
}

.pagination span {
  font-family: 'Minecraftia', sans-serif;
  font-size: 1.5em;
  margin: 0 10px;
  position: relative;
  top: 1px;
}

.pagination button {
  font-family: 'Minecraftia', sans-serif;
  font-size: 1.5em;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px 15px;
  margin: 0 5px;
  cursor: pointer;
  user-select: none;
}

.pagination button:disabled {
  color: grey;
  cursor: default;
}

.pagination .active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.gallery-description {
  font-family: 'Minecraftia', sans-serif;
  font-size: 0.75rem;
  text-align: center;
  margin: 0 auto;
  padding: 0 20px;
}

.wallet-connect-label {
  font-family: 'Minecraftia', sans-serif;
  text-align: center;
  margin: 20px;
}

.title {
  font-family: 'Minecraftia', sans-serif;
  text-align: center;
  font-size: 1.25rem;
  margin: 20px 0;
}


@media (max-width: 768px) {
  /* Adjust the grid layout for smaller screens */
  .skins-grid {
    grid-template-columns: repeat(2, minmax(125px, 1fr)); /* Two items per row, each item at least 150px wide */
    gap: 5px; /* Reduce the gap between grid items */
    padding: 10px; 
  }

  /* Reduce the font sizes for mobile devices */
  .skin-details {
    font-size: 0.75rem; /* Smaller text for the skin details */
  }

  .skin-details strong {
    font-size: 0.65rem; /* Even smaller font size for strong text */
  }

  .like-dislike-interactions button {
    padding: 5px 8px; /* Smaller padding for the buttons */
    font-size: 0.7rem; /* Smaller font size for the buttons */
  }

  .pagination span {
    font-size: 1em; /* Smaller font size for pagination numbers */
  }

  .pagination button {
    padding: 5px 10px; /* Smaller padding for pagination buttons */
    font-size: 1em; /* Smaller font size for pagination buttons */
  }

  .title {
    font-size: 1rem; /* Smaller title font size */
  }

  .gallery-description {
    font-size: 0.65rem; /* Smaller description font size */
    padding: 0 15px; /* Adjust padding to ensure it fits on smaller screens */
  }
}

/* If needed, add another query for even smaller screens */
@media (max-width: 480px) {
  /* Further adjust the styles for very small screens */
  .skins-grid {
    grid-template-columns: fr; /* Single column layout for very small screens */
  }

  /* And so on for other elements... */
}